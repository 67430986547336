.excel-analyzer {
  padding: 40px;
  max-width: 1400px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.excel-analyzer h2 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
}

.upload-section {
  margin: 20px 0;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-section:hover {
  border-color: #2980b9;
  background-color: #f1f5f9;
}

.file-input-wrapper {
  display: inline-block;
}

.file-input-button {
  display: inline-block;
  padding: 8px 16px;
  background: #1890ff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.file-input-button:hover {
  background: #40a9ff;
}

.file-input-button input[type="file"] {
  display: none;
}

.search-section {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.search-input {
  min-width: 200px;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.3s;
}

.search-input:hover {
  border-color: #40a9ff;
}

.search-input:focus {
  border-color: #40a9ff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(24,144,255,0.2);
}

.ant-picker {
  height: 40px !important;
  border-radius: 6px !important;
  border: 1px solid #d9d9d9 !important;
  transition: all 0.3s !important;
}

.ant-picker:hover {
  border-color: #40a9ff !important;
}

.ant-picker-focused {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24,144,255,0.2) !important;
}

.ant-picker-range {
  width: 360px !important;
  padding: 4px 11px !important;
}

.ant-picker-dropdown {
  padding: 12px !important;
  border-radius: 8px !important;
  box-shadow: 0 6px 16px rgba(0,0,0,0.08) !important;
  z-index: 1050 !important;
}

.ant-picker-panel-container {
  border-radius: 8px !important;
  overflow: hidden !important;
}

.ant-picker-header {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 12px 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  min-height: 48px !important;
}

.ant-picker-header > button {
  padding: 0 8px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  line-height: 40px !important;
  height: 40px !important;
  border: 0 !important;
  background: transparent !important;
  font-size: 16px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.ant-picker-header > button:hover {
  color: #1890ff !important;
}

.ant-picker-header-view {
  flex: auto !important;
  font-weight: 500 !important;
  line-height: 40px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  text-align: center !important;
  font-size: 14px !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn {
  position: static !important;
  display: inline-flex !important;
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
}

.ant-picker-content th {
  height: 30px !important;
  color: #666 !important;
}

.ant-picker-cell {
  padding: 4px 0 !important;
}

.ant-picker-cell-inner {
  height: 28px !important;
  width: 28px !important;
  line-height: 28px !important;
  border-radius: 4px !important;
  margin: auto !important;
}

.ant-picker-cell-in-view {
  color: #262626 !important;
}

.ant-picker-cell:hover .ant-picker-cell-inner {
  background: #f5f5f5 !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #1890ff !important;
  color: #fff !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #1890ff !important;
}

.search-button {
  height: 40px;
  padding: 0 20px;
  background: #1890ff;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 12px;
}

.search-button:hover {
  background: #40a9ff;
  box-shadow: 0 2px 8px rgba(24,144,255,0.35);
}

.search-button:active {
  background: #096dd9;
}

.data-list {
  margin-top: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: auto;
}

.table-responsive {
  overflow-x: auto;
  padding: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  white-space: nowrap;
}

th {
  background: #fafafa;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
}

tr:hover {
  background: #fafafa;
}

.loading {
  text-align: center;
  padding: 40px;
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data {
  text-align: center;
  padding: 40px;
  color: rgba(0,0,0,0.45);
}

/* 适暗色主题 */
@media (prefers-color-scheme: dark) {
  .search-section,
  .upload-section,
  .data-list {
    background: #1f1f1f;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .search-input {
    background: #141414;
    border-color: #434343;
    color: rgba(255,255,255,0.85);
  }

  .ant-picker {
    background: #141414 !important;
    border-color: #434343 !important;
  }

  .ant-picker-input > input {
    color: rgba(255,255,255,0.85) !important;
  }

  .ant-picker-panel-container {
    background: #1f1f1f !important;
  }

  .ant-picker-header {
    border-bottom: 1px solid #303030 !important;
  }

  .ant-picker-header > button {
    color: rgba(255, 255, 255, 0.45) !important;
  }

  .ant-picker-header > button:hover {
    color: #177ddc !important;
  }

  .ant-picker-header-view {
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-picker-content th {
    color: rgba(255,255,255,0.45) !important;
  }

  .ant-picker-cell-in-view {
    color: rgba(255,255,255,0.85) !important;
  }

  .ant-picker-cell:hover .ant-picker-cell-inner {
    background: #303030 !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #177ddc !important;
  }

  .search-tip {
    color: rgba(255,255,255,0.45);
  }

  th {
    background: #1f1f1f;
    color: rgba(255,255,255,0.85);
  }

  td {
    border-bottom-color: #303030;
  }

  tr:hover {
    background: #262626;
  }

  .no-data {
    color: rgba(255,255,255,0.45);
  }

  /* 暗色主题下的日期选择器样式 */
  .ant-picker-dropdown.ant-picker-dropdown-dark {
    background: #1f1f1f;
  }

  .ant-picker-panel-container.ant-picker-panel-container-dark {
    background: #1f1f1f;
  }

  .ant-picker-cell-in-view.ant-picker-cell-dark {
    color: rgba(255,255,255,0.85);
  }
}

.search-tip {
  margin-top: 8px;
  color: #8c8c8c;
  font-size: 13px;
}

/* 错误消息样式 */
.error-message {
  color: #ff4d4f;
  margin-top: 8px;
  font-size: 13px;
}

/* 搜索区域布局调整 */
.search-section .ant-space {
  width: 100%;
}

.search-section .ant-space-item {
  margin-right: 16px;
}

/* 确保日期选择器下拉框不会被截断 */
.ant-picker-dropdown {
  position: fixed;
}

.pagination-container {
  margin-top: 20px;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .pagination-container {
    background: #1f1f1f;
    border-top: 1px solid #303030;
  }

  .ant-pagination-item {
    background-color: #141414 !important;
    border-color: #434343 !important;
  }

  .ant-pagination-item a {
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-pagination-item-active {
    background-color: #177ddc !important;
    border-color: #177ddc !important;
  }

  .ant-pagination-item-active a {
    color: #fff !important;
  }

  .ant-pagination-prev button,
  .ant-pagination-next button {
    background-color: #141414 !important;
    border-color: #434343 !important;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-select-selector {
    background-color: #141414 !important;
    border-color: #434343 !important;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-pagination-options-quick-jumper input {
    background-color: #141414 !important;
    border-color: #434343 !important;
    color: rgba(255, 255, 255, 0.85) !important;
  }
}

/* 其他样式保持不变... */ 