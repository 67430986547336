.user-management {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
}

.header-actions {
  display: flex;
  gap: 10px;
}

.add-button, .delete-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.add-button:hover {
  background-color: #45a049;
}

.delete-button:hover:not(:disabled) {
  background-color: #da190b;
}

.delete-button:disabled {
  background-color: #ffcdd2;
  cursor: not-allowed;
  opacity: 0.7;
}

.users-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  font-weight: 600;
}

th:first-child, td:first-child {
  width: 40px;
  text-align: center;
}

th input[type="checkbox"], td input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

tr:hover {
  background-color: #f9f9f9;
}

.status {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.status.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status.inactive {
  background-color: #ffebee;
  color: #c62828;
}

.permission-tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  background-color: #e3f2fd;
  color: #1976d2;
  border-radius: 4px;
  font-size: 12px;
}

.edit-button {
  padding: 6px 12px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.edit-button:hover {
  background-color: #1976d2;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  color: #666;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 23, 42, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  width: 90%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  padding: 14px 20px;
  background: linear-gradient(to right, #1a237e, #0d47a1);
  color: white;
  display: flex;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #718096;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #4a5568;
}

.modal-content {
  padding: 20px;
}

.user-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-basic-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 20px;
}

.form-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-group label {
  font-size: 13px;
  font-weight: 500;
  color: #4b5563;
  min-width: 60px;
}

.form-group .basic-input {
  width: 66.67%;
  flex: none;
  height: 28px;
  padding: 0 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 13px;
  color: #1f2937;
  background-color: #ffffff;
}

.form-group .basic-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.form-group .basic-input:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  color: #6b7280;
}

.permissions {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
}

.permissions .form-group {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.permission-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
}

.permission-options label {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: #4b5563;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 4px;
  min-width: auto;
}

.permission-options input[type="checkbox"] {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  border: 1px solid #d1d5db;
  cursor: pointer;
  position: relative;
  margin: 0;
}

.permission-options input[type="checkbox"]:checked {
  background-color: #2563eb;
  border-color: #2563eb;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 14px 20px;
  background-color: #f8fafc;
  border-top: 1px solid #e2e8f0;
}

.submit-button,
.cancel-button {
  min-width: 76px;
  height: 32px;
  padding: 0 16px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.submit-button {
  background-color: #2563eb;
  color: white;
}

.submit-button:hover {
  background-color: #1d4ed8;
}

.cancel-button {
  background-color: #f1f5f9;
  color: #64748b;
}

.cancel-button:hover {
  background-color: #e2e8f0;
  color: #475569;
}

.error-message {
  color: #e53e3e;
  margin-top: 4px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.error-message i {
  font-size: 14px;
} 